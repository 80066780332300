var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { staticClass: "mb-14", attrs: { flat: "" } },
    [
      _c("v-card-title", [
        _c("span", { staticClass: "secondary--text font-weight-bold" }, [
          _vm._v("Add New Application"),
        ]),
      ]),
      _vm.applicationFamilies.length && !_vm.fetchingApplications
        ? _c(
            "div",
            [
              _c(
                "v-card-text",
                [
                  _c("v-autocomplete", {
                    ref: "tagSearch",
                    attrs: {
                      solo: "",
                      multiple: "",
                      chips: "",
                      clearable: "",
                      "deletable-chips": "",
                      items: _vm.tagList,
                      label: "Type here to search",
                    },
                    on: { "update:search-input": _vm.searchTermUpdated },
                    model: {
                      value: _vm.selectedTags,
                      callback: function ($$v) {
                        _vm.selectedTags = $$v
                      },
                      expression: "selectedTags",
                    },
                  }),
                  _c(
                    "div",
                    { staticClass: "mb-6" },
                    [
                      _c(
                        "v-chip-group",
                        { attrs: { "show-arrows": "", multiple: "" } },
                        _vm._l(_vm.tagList, function (tag, index) {
                          return _c(
                            "v-chip",
                            {
                              key: `tag-${index}`,
                              staticClass: "mr-2 my-1",
                              attrs: {
                                label: "",
                                color: _vm.selectedTags.includes(tag)
                                  ? "primary"
                                  : undefined,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.tagChipClicked(tag)
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(tag) + " ")]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-tabs",
                    {
                      attrs: { centered: "" },
                      model: {
                        value: _vm.selectedTab,
                        callback: function ($$v) {
                          _vm.selectedTab = $$v
                        },
                        expression: "selectedTab",
                      },
                    },
                    [
                      _vm._l(_vm.applicationFamilyGroups, function (group, i) {
                        return _c("v-tab", { key: `group-${i}` }, [
                          _c("div", { staticClass: "d-flex flex-column" }, [
                            _c("div", [
                              _c("strong", [_vm._v(_vm._s(group.name))]),
                              _vm._v(" (" + _vm._s(group.apps.length) + ") "),
                            ]),
                          ]),
                        ])
                      }),
                      _vm._l(
                        _vm.applicationFamilyGroups,
                        function (applicationFamilies, index) {
                          return _c(
                            "v-tab-item",
                            { key: `tab-${index}` },
                            [
                              _c(
                                "v-container",
                                { attrs: { fluid: "" } },
                                [
                                  _vm.applicationFamilyGroups.length > 1
                                    ? _c(
                                        "v-row",
                                        [
                                          _vm._l(
                                            _vm.filteredFamilies,
                                            function (app, index) {
                                              return _c(
                                                "v-col",
                                                {
                                                  key: `app-${index}`,
                                                  staticClass: "mb-1",
                                                  attrs: {
                                                    cols: "12",
                                                    sm: "6",
                                                    md: "4",
                                                    lg: "3",
                                                    xl: "2",
                                                  },
                                                },
                                                [
                                                  app.disabled_reason != null
                                                    ? _c(
                                                        "v-tooltip",
                                                        {
                                                          attrs: {
                                                            "close-delay": 3,
                                                            bottom: "",
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "activator",
                                                                fn: function ({
                                                                  on,
                                                                }) {
                                                                  return [
                                                                    _c(
                                                                      "div",
                                                                      _vm._g(
                                                                        {},
                                                                        on
                                                                      ),
                                                                      [
                                                                        _c(
                                                                          "v-card",
                                                                          {
                                                                            staticStyle:
                                                                              {
                                                                                filter:
                                                                                  "grayscale(100%)",
                                                                              },
                                                                            attrs:
                                                                              {
                                                                                flat: "",
                                                                                color:
                                                                                  "grey lighten-3",
                                                                                disabled:
                                                                                  app.disabled_reason !=
                                                                                  null,
                                                                              },
                                                                            on: {
                                                                              click:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  _vm.selectedApp =
                                                                                    app
                                                                                },
                                                                            },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "v-list-item",
                                                                              {
                                                                                staticClass:
                                                                                  "py-2",
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "v-list-item-avatar",
                                                                                  {
                                                                                    attrs:
                                                                                      {
                                                                                        tile: "",
                                                                                      },
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "img",
                                                                                      {
                                                                                        attrs:
                                                                                          {
                                                                                            src: app.icon_url,
                                                                                          },
                                                                                      }
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                                _c(
                                                                                  "v-list-item-content",
                                                                                  [
                                                                                    _c(
                                                                                      "v-list-item-title",
                                                                                      {
                                                                                        staticClass:
                                                                                          "mr-1 text-truncate secondary--text header-font",
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          " " +
                                                                                            _vm._s(
                                                                                              app.name
                                                                                            ) +
                                                                                            " "
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                  ],
                                                                                  1
                                                                                ),
                                                                              ],
                                                                              1
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ]
                                                                },
                                                              },
                                                            ],
                                                            null,
                                                            true
                                                          ),
                                                        },
                                                        [
                                                          _vm
                                                            .appDisabledReasons[
                                                            app.disabled_reason
                                                          ]
                                                            ? _c("span", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm
                                                                      .appDisabledReasons[
                                                                      app
                                                                        .disabled_reason
                                                                    ]
                                                                  )
                                                                ),
                                                              ])
                                                            : _c("span", [
                                                                _vm._v(
                                                                  "This application is not available"
                                                                ),
                                                              ]),
                                                        ]
                                                      )
                                                    : _c(
                                                        "v-card",
                                                        {
                                                          attrs: {
                                                            flat: "",
                                                            color: `${
                                                              _vm.selectedApp ===
                                                              app
                                                                ? "grey"
                                                                : "white"
                                                            } lighten-3`,
                                                            elevation:
                                                              _vm.selectedApp ===
                                                              app
                                                                ? 0
                                                                : 4,
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.selectAndScroll(
                                                                app
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "v-list-item",
                                                            {
                                                              staticClass:
                                                                "py-2",
                                                            },
                                                            [
                                                              _c(
                                                                "v-list-item-avatar",
                                                                {
                                                                  attrs: {
                                                                    tile: "",
                                                                  },
                                                                },
                                                                [
                                                                  _c("img", {
                                                                    attrs: {
                                                                      src: app.icon_url,
                                                                    },
                                                                  }),
                                                                ]
                                                              ),
                                                              _c(
                                                                "v-list-item-content",
                                                                [
                                                                  _c(
                                                                    "v-list-item-title",
                                                                    {
                                                                      staticClass:
                                                                        "mr-1 text-truncate secondary--text header-font",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            app.name
                                                                          ) +
                                                                          " "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                ],
                                                1
                                              )
                                            }
                                          ),
                                          _vm.familiesToDisplay <=
                                          _vm.filteredFamilies.length
                                            ? _c(
                                                "v-col",
                                                {
                                                  staticClass: "mb-1",
                                                  attrs: {
                                                    cols: "12",
                                                    sm: "6",
                                                    md: "4",
                                                    lg: "3",
                                                    xl: "2",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "v-card",
                                                    {
                                                      attrs: { flat: "" },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          _vm.displayAllFamilies =
                                                            !_vm.displayAllFamilies
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "v-list-item",
                                                        { staticClass: "py-2" },
                                                        [
                                                          _c(
                                                            "v-list-item-avatar",
                                                            {
                                                              attrs: {
                                                                tile: "",
                                                              },
                                                            },
                                                            [
                                                              _c("v-icon", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.displayAllFamilies
                                                                      ? "mdi-chevron-left"
                                                                      : "mdi-chevron-right"
                                                                  )
                                                                ),
                                                              ]),
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "v-list-item-content",
                                                            [
                                                              _c(
                                                                "v-list-item-title",
                                                                {
                                                                  staticClass:
                                                                    "mr-1 text-truncate secondary--text header-font",
                                                                },
                                                                [
                                                                  _c("span", [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.displayAllFamilies
                                                                          ? "Display less"
                                                                          : "Display all"
                                                                      )
                                                                    ),
                                                                  ]),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ],
                                        2
                                      )
                                    : _c(
                                        "v-row",
                                        { attrs: { justify: "center" } },
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "text-center",
                                              attrs: { cols: "12", sm: "6" },
                                            },
                                            [
                                              _c(
                                                "v-alert",
                                                {
                                                  staticClass: "mt-3",
                                                  attrs: {
                                                    value: true,
                                                    type: "info",
                                                    prominent: "",
                                                    text: "",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "d-flex flex-column",
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "font-weight-bold",
                                                        },
                                                        [
                                                          _vm._v(
                                                            "No applications found."
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(
                                                        " Try removing or changing some tags to get more results. "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        }
                      ),
                    ],
                    2
                  ),
                  _c(
                    "div",
                    { ref: "appDetails" },
                    [
                      _c(
                        "v-fade-transition",
                        [
                          _vm.selectedApp
                            ? _c(
                                "v-container",
                                { attrs: { fluid: "" } },
                                [
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          attrs: {
                                            cols: "12",
                                            sm: "4",
                                            lg: "3",
                                            xl: "2",
                                          },
                                        },
                                        [
                                          _c(
                                            "v-card",
                                            { staticClass: "mt-13" },
                                            [
                                              _c("v-card-title", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.selectedApp?.name
                                                    ) +
                                                    " "
                                                ),
                                              ]),
                                              _c(
                                                "v-card-text",
                                                [
                                                  _c("v-img", {
                                                    staticClass: "ma-auto",
                                                    attrs: {
                                                      width: "50%",
                                                      src: _vm.selectedApp
                                                        ?.icon_url,
                                                      contain: "",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-card-text",
                                                [
                                                  _c("viewer", {
                                                    ref: "appDescription",
                                                    attrs: {
                                                      id: "appDescription",
                                                      options:
                                                        _vm.defaultOptions,
                                                      initialValue:
                                                        _vm.selectedApp
                                                          ?.description,
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          attrs: {
                                            cols: "12",
                                            sm: "8",
                                            lg: "9",
                                            xl: "10",
                                          },
                                        },
                                        [
                                          _c(
                                            "v-row",
                                            { attrs: { "no-gutters": "" } },
                                            [
                                              _c(
                                                "v-col",
                                                {
                                                  attrs: {
                                                    cols: "8",
                                                    lg: "7",
                                                    xl: "8",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "pa-4" },
                                                    [_vm._v("Version")]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "v-col",
                                                {
                                                  attrs: {
                                                    cols: "4",
                                                    lg: "3",
                                                    xl: "2",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "pa-4" },
                                                    [_vm._v("Release date")]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass:
                                                    "d-none d-lg-flex justify-end",
                                                  attrs: { lg: "2", xl: "2" },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "pa-4" },
                                                    [_vm._v("Actions")]
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c("v-data-iterator", {
                                            attrs: {
                                              items: _vm.filteredApps,
                                              "items-per-page":
                                                _vm.itemsPerPage,
                                              "no-data-text":
                                                "No application selected",
                                              "hide-default-footer":
                                                _vm.addingApp || _vm.appAdded,
                                            },
                                            on: {
                                              "update:itemsPerPage": function (
                                                $event
                                              ) {
                                                _vm.itemsPerPage = $event
                                              },
                                              "update:items-per-page":
                                                function ($event) {
                                                  _vm.itemsPerPage = $event
                                                },
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function ({ items }) {
                                                    return [
                                                      _c(
                                                        "v-row",
                                                        _vm._l(
                                                          items,
                                                          function (item) {
                                                            return _c(
                                                              "v-col",
                                                              {
                                                                key: item.imid,
                                                                attrs: {
                                                                  cols: "12",
                                                                },
                                                              },
                                                              [
                                                                item.disabled_reason !=
                                                                null
                                                                  ? _c(
                                                                      "v-tooltip",
                                                                      {
                                                                        attrs: {
                                                                          "close-delay": 3,
                                                                          bottom:
                                                                            "",
                                                                        },
                                                                        scopedSlots:
                                                                          _vm._u(
                                                                            [
                                                                              {
                                                                                key: "activator",
                                                                                fn: function ({
                                                                                  on,
                                                                                }) {
                                                                                  return [
                                                                                    _c(
                                                                                      "div",
                                                                                      _vm._g(
                                                                                        {},
                                                                                        on
                                                                                      ),
                                                                                      [
                                                                                        _c(
                                                                                          "v-card",
                                                                                          {
                                                                                            attrs:
                                                                                              {
                                                                                                elevation:
                                                                                                  "1",
                                                                                                disabled:
                                                                                                  item.disabled_reason !=
                                                                                                  null,
                                                                                              },
                                                                                          },
                                                                                          [
                                                                                            _c(
                                                                                              "v-row",
                                                                                              {
                                                                                                attrs:
                                                                                                  {
                                                                                                    "no-gutters":
                                                                                                      "",
                                                                                                  },
                                                                                              },
                                                                                              [
                                                                                                _c(
                                                                                                  "v-col",
                                                                                                  {
                                                                                                    attrs:
                                                                                                      {
                                                                                                        cols: "8",
                                                                                                        lg: "7",
                                                                                                        xl: "8",
                                                                                                      },
                                                                                                  },
                                                                                                  [
                                                                                                    _c(
                                                                                                      "v-list-item",
                                                                                                      {
                                                                                                        attrs:
                                                                                                          {
                                                                                                            "two-line":
                                                                                                              "",
                                                                                                          },
                                                                                                      },
                                                                                                      [
                                                                                                        _c(
                                                                                                          "v-list-item-content",
                                                                                                          [
                                                                                                            _c(
                                                                                                              "v-list-item-title",
                                                                                                              {
                                                                                                                staticClass:
                                                                                                                  "mr-1 text-truncate secondary--text header-font",
                                                                                                              },
                                                                                                              [
                                                                                                                _vm._v(
                                                                                                                  " " +
                                                                                                                    _vm._s(
                                                                                                                      item.name
                                                                                                                    ) +
                                                                                                                    " "
                                                                                                                ),
                                                                                                              ]
                                                                                                            ),
                                                                                                            _c(
                                                                                                              "v-list-item-subtitle",
                                                                                                              [
                                                                                                                _c(
                                                                                                                  "span",
                                                                                                                  {
                                                                                                                    staticClass:
                                                                                                                      "mr-3",
                                                                                                                  },
                                                                                                                  [
                                                                                                                    _vm._v(
                                                                                                                      _vm._s(
                                                                                                                        item.short_description
                                                                                                                      )
                                                                                                                    ),
                                                                                                                  ]
                                                                                                                ),
                                                                                                              ]
                                                                                                            ),
                                                                                                          ],
                                                                                                          1
                                                                                                        ),
                                                                                                      ],
                                                                                                      1
                                                                                                    ),
                                                                                                  ],
                                                                                                  1
                                                                                                ),
                                                                                                _c(
                                                                                                  "v-col",
                                                                                                  {
                                                                                                    staticClass:
                                                                                                      "d-flex align-center",
                                                                                                    attrs:
                                                                                                      {
                                                                                                        cols: "4",
                                                                                                        lg: "3",
                                                                                                        xl: "2",
                                                                                                      },
                                                                                                  },
                                                                                                  [
                                                                                                    _c(
                                                                                                      "div",
                                                                                                      {
                                                                                                        staticClass:
                                                                                                          "px-4",
                                                                                                      },
                                                                                                      [
                                                                                                        _vm._v(
                                                                                                          " " +
                                                                                                            _vm._s(
                                                                                                              _vm._f(
                                                                                                                "dateToHuman"
                                                                                                              )(
                                                                                                                item.release_date
                                                                                                              )
                                                                                                            ) +
                                                                                                            " "
                                                                                                        ),
                                                                                                      ]
                                                                                                    ),
                                                                                                  ]
                                                                                                ),
                                                                                                _c(
                                                                                                  "v-col",
                                                                                                  {
                                                                                                    staticClass:
                                                                                                      "d-flex align-center justify-end pa-4",
                                                                                                    attrs:
                                                                                                      {
                                                                                                        cols: "12",
                                                                                                        lg: "2",
                                                                                                      },
                                                                                                  },
                                                                                                  [
                                                                                                    _c(
                                                                                                      "v-btn",
                                                                                                      {
                                                                                                        attrs:
                                                                                                          {
                                                                                                            color:
                                                                                                              "primary",
                                                                                                            disabled:
                                                                                                              item.disabled_reason !=
                                                                                                              null,
                                                                                                            loading:
                                                                                                              _vm.addingApp,
                                                                                                          },
                                                                                                        on: {
                                                                                                          click:
                                                                                                            function (
                                                                                                              $event
                                                                                                            ) {
                                                                                                              $event.stopPropagation()
                                                                                                              return _vm.createAppVersion(
                                                                                                                item
                                                                                                              )
                                                                                                            },
                                                                                                        },
                                                                                                      },
                                                                                                      [
                                                                                                        _c(
                                                                                                          "v-icon",
                                                                                                          {
                                                                                                            attrs:
                                                                                                              {
                                                                                                                small:
                                                                                                                  "",
                                                                                                              },
                                                                                                          },
                                                                                                          [
                                                                                                            _vm._v(
                                                                                                              "add"
                                                                                                            ),
                                                                                                          ]
                                                                                                        ),
                                                                                                        _vm._v(
                                                                                                          " Install "
                                                                                                        ),
                                                                                                      ],
                                                                                                      1
                                                                                                    ),
                                                                                                  ],
                                                                                                  1
                                                                                                ),
                                                                                              ],
                                                                                              1
                                                                                            ),
                                                                                          ],
                                                                                          1
                                                                                        ),
                                                                                      ],
                                                                                      1
                                                                                    ),
                                                                                  ]
                                                                                },
                                                                              },
                                                                            ],
                                                                            null,
                                                                            true
                                                                          ),
                                                                      },
                                                                      [
                                                                        _vm
                                                                          .appDisabledReasons[
                                                                          item
                                                                            .disabled_reason
                                                                        ]
                                                                          ? _c(
                                                                              "span",
                                                                              [
                                                                                _vm._v(
                                                                                  " " +
                                                                                    _vm._s(
                                                                                      _vm
                                                                                        .appDisabledReasons[
                                                                                        item
                                                                                          .disabled_reason
                                                                                      ]
                                                                                    ) +
                                                                                    " "
                                                                                ),
                                                                              ]
                                                                            )
                                                                          : _c(
                                                                              "span",
                                                                              [
                                                                                _vm._v(
                                                                                  "This application is not available"
                                                                                ),
                                                                              ]
                                                                            ),
                                                                      ]
                                                                    )
                                                                  : _c(
                                                                      "version-description-viewer",
                                                                      {
                                                                        attrs: {
                                                                          name: _vm
                                                                            .selectedVersion
                                                                            ?.name,
                                                                          iconUrl:
                                                                            _vm
                                                                              .selectedApp
                                                                              ?.icon_url,
                                                                          descriptionUrl:
                                                                            _vm
                                                                              .selectedVersion
                                                                              ?.description,
                                                                          disabled:
                                                                            _vm.addingApp ||
                                                                            _vm.appAdded,
                                                                          versionTags:
                                                                            _vm
                                                                              .selectedVersion
                                                                              ?.tags,
                                                                          selectedTags:
                                                                            _vm.selectedTags,
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-card",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                elevation:
                                                                                  "6",
                                                                                disabled:
                                                                                  _vm.addingApp ||
                                                                                  _vm.appAdded,
                                                                              },
                                                                            on: {
                                                                              click:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.selectAppVersion(
                                                                                    item
                                                                                  )
                                                                                },
                                                                            },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "v-row",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    "no-gutters":
                                                                                      "",
                                                                                  },
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "v-col",
                                                                                  {
                                                                                    attrs:
                                                                                      {
                                                                                        cols: "8",
                                                                                        lg: "7",
                                                                                        xl: "8",
                                                                                      },
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "v-list-item",
                                                                                      {
                                                                                        attrs:
                                                                                          {
                                                                                            "two-line":
                                                                                              "",
                                                                                          },
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "v-list-item-content",
                                                                                          [
                                                                                            _c(
                                                                                              "v-list-item-title",
                                                                                              {
                                                                                                staticClass:
                                                                                                  "mr-1 text-truncate secondary--text header-font",
                                                                                              },
                                                                                              [
                                                                                                _vm._v(
                                                                                                  " " +
                                                                                                    _vm._s(
                                                                                                      item.name
                                                                                                    ) +
                                                                                                    " "
                                                                                                ),
                                                                                              ]
                                                                                            ),
                                                                                            _c(
                                                                                              "v-list-item-subtitle",
                                                                                              [
                                                                                                _c(
                                                                                                  "span",
                                                                                                  {
                                                                                                    staticClass:
                                                                                                      "mr-3",
                                                                                                  },
                                                                                                  [
                                                                                                    _vm._v(
                                                                                                      _vm._s(
                                                                                                        item.short_description
                                                                                                      )
                                                                                                    ),
                                                                                                  ]
                                                                                                ),
                                                                                              ]
                                                                                            ),
                                                                                          ],
                                                                                          1
                                                                                        ),
                                                                                      ],
                                                                                      1
                                                                                    ),
                                                                                  ],
                                                                                  1
                                                                                ),
                                                                                _c(
                                                                                  "v-col",
                                                                                  {
                                                                                    staticClass:
                                                                                      "d-flex align-center",
                                                                                    attrs:
                                                                                      {
                                                                                        cols: "4",
                                                                                        lg: "3",
                                                                                        xl: "2",
                                                                                      },
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "div",
                                                                                      {
                                                                                        staticClass:
                                                                                          "px-4",
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          " " +
                                                                                            _vm._s(
                                                                                              _vm._f(
                                                                                                "dateToHuman"
                                                                                              )(
                                                                                                item.release_date
                                                                                              )
                                                                                            ) +
                                                                                            " "
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                                _c(
                                                                                  "v-col",
                                                                                  {
                                                                                    staticClass:
                                                                                      "d-flex align-center justify-end pa-4",
                                                                                    attrs:
                                                                                      {
                                                                                        cols: "12",
                                                                                        lg: "2",
                                                                                      },
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "v-btn",
                                                                                      {
                                                                                        attrs:
                                                                                          {
                                                                                            color:
                                                                                              _vm.isVersionAdded(
                                                                                                item
                                                                                              )
                                                                                                ? "success"
                                                                                                : "primary",
                                                                                            loading:
                                                                                              _vm.isCurrentVersion(
                                                                                                item
                                                                                              ) &&
                                                                                              _vm.addingApp,
                                                                                          },
                                                                                        on: {
                                                                                          click:
                                                                                            function (
                                                                                              $event
                                                                                            ) {
                                                                                              $event.stopPropagation()
                                                                                              return _vm.createAppVersion(
                                                                                                item
                                                                                              )
                                                                                            },
                                                                                        },
                                                                                      },
                                                                                      [
                                                                                        _vm.isVersionAdded(
                                                                                          item
                                                                                        )
                                                                                          ? [
                                                                                              _c(
                                                                                                "v-icon",
                                                                                                {
                                                                                                  attrs:
                                                                                                    {
                                                                                                      small:
                                                                                                        "",
                                                                                                    },
                                                                                                },
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    "check"
                                                                                                  ),
                                                                                                ]
                                                                                              ),
                                                                                            ]
                                                                                          : [
                                                                                              _c(
                                                                                                "v-icon",
                                                                                                {
                                                                                                  attrs:
                                                                                                    {
                                                                                                      small:
                                                                                                        "",
                                                                                                    },
                                                                                                },
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    "add"
                                                                                                  ),
                                                                                                ]
                                                                                              ),
                                                                                              _vm._v(
                                                                                                " Install "
                                                                                              ),
                                                                                            ],
                                                                                      ],
                                                                                      2
                                                                                    ),
                                                                                  ],
                                                                                  1
                                                                                ),
                                                                              ],
                                                                              1
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                              ],
                                                              1
                                                            )
                                                          }
                                                        ),
                                                        1
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              false,
                                              1153463576
                                            ),
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c("v-spacer"),
                  _vm.createSpaceAndApp.operationSelected
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "ma-6",
                          attrs: {
                            disabled: _vm.loading,
                            outlined: "",
                            text: "",
                            color: "primary",
                            exact: "",
                          },
                          on: { click: _vm.skipAppCreation },
                        },
                        [_vm._v(" skip this step ")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          )
        : _vm.fetchingApplications
        ? _c(
            "div",
            { staticClass: "applications-fetching" },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c("v-skeleton-loader", {
                        staticClass: "mx-auto",
                        attrs: { type: "list-item" },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { staticClass: "d-flex mb-8", attrs: { cols: "12" } },
                    _vm._l(6, function (i) {
                      return _c(
                        "div",
                        { key: i, staticClass: "px-4" },
                        [_c("v-skeleton-loader", { attrs: { type: "chip" } })],
                        1
                      )
                    }),
                    0
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "d-flex justify-center",
                      attrs: { cols: "12" },
                    },
                    _vm._l(6, function (i) {
                      return _c(
                        "div",
                        { key: i, staticClass: "px-4" },
                        [
                          _c("v-skeleton-loader", {
                            attrs: { type: "button" },
                          }),
                        ],
                        1
                      )
                    }),
                    0
                  ),
                  _vm._l(_vm.filteredApps.length, function (n) {
                    return _c(
                      "v-col",
                      {
                        key: n,
                        attrs: {
                          cols: "12",
                          sm: "6",
                          md: "4",
                          lg: "3",
                          xl: "2",
                        },
                      },
                      [
                        _c("v-skeleton-loader", {
                          staticClass: "mx-auto",
                          attrs: { type: "list-item-avatar" },
                        }),
                      ],
                      1
                    )
                  }),
                ],
                2
              ),
            ],
            1
          )
        : !_vm.fetchingApplications && !_vm.applicationFamilies.length
        ? _c(
            "div",
            { staticClass: "empty-applications-warning" },
            [
              _c(
                "v-alert",
                {
                  staticClass: "mt-3",
                  attrs: {
                    value: true,
                    type: "warning",
                    prominent: "",
                    text: "",
                  },
                },
                [
                  _c("div", { staticClass: "d-flex flex-column" }, [
                    _c("span", { staticClass: "font-weight-bold" }, [
                      _vm._v("No applications available."),
                    ]),
                    _vm._v(
                      " Try refreshing the page, and if the problem persists contact the space administrator. "
                    ),
                  ]),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-dialog",
        {
          attrs: { width: "40%", persistent: "" },
          model: {
            value: _vm.createDialog,
            callback: function ($$v) {
              _vm.createDialog = $$v
            },
            expression: "createDialog",
          },
        },
        [
          _c(
            "v-card",
            { attrs: { disabled: _vm.appAdded } },
            [
              _c("v-card-title", [_vm._v("Pick a name for your application")]),
              _c("v-card-subtitle", { staticClass: "caption" }, [
                _vm._v(
                  "Application name already exists: " + _vm._s(_vm.appNameTaken)
                ),
              ]),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-form",
                    {
                      model: {
                        value: _vm.appDescriptionIsValid,
                        callback: function ($$v) {
                          _vm.appDescriptionIsValid = $$v
                        },
                        expression: "appDescriptionIsValid",
                      },
                    },
                    [
                      _c("v-text-field", {
                        attrs: {
                          required: "",
                          rules: [_vm.rules.nonEmpty],
                          outlined: "",
                        },
                        on: { focus: _vm.selectAll },
                        scopedSlots: _vm._u([
                          {
                            key: "label",
                            fn: function () {
                              return [_vm._v("Type a name here")]
                            },
                            proxy: true,
                          },
                        ]),
                        model: {
                          value: _vm.appName,
                          callback: function ($$v) {
                            _vm.appName = $$v
                          },
                          expression: "appName",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm.errorContent
                    ? _c(
                        "v-alert",
                        {
                          attrs: {
                            type: "warning",
                            text: "",
                            icon: "mdi-alert-circle-outline",
                          },
                        },
                        [_c("span", [_vm._v(_vm._s(_vm.errorContent))])]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  !_vm.appAdded
                    ? _c(
                        "v-btn",
                        {
                          attrs: { text: "", disabled: _vm.addingApp },
                          on: {
                            click: function ($event) {
                              _vm.createDialog = false
                            },
                          },
                        },
                        [_vm._v("Cancel")]
                      )
                    : _vm._e(),
                  _vm.appAdded
                    ? _c(
                        "v-btn",
                        { attrs: { icon: "" } },
                        [
                          _c("v-icon", { attrs: { color: "success" } }, [
                            _vm._v("check"),
                          ]),
                        ],
                        1
                      )
                    : _c(
                        "v-btn",
                        {
                          attrs: {
                            text: "",
                            color: "primary",
                            disabled: !_vm.appDescriptionIsValid,
                            loading: _vm.addingApp,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.createApp()
                            },
                          },
                        },
                        [_vm._v("Create")]
                      ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }